import React, {useState} from "react";
import {useSearchParams} from "react-router-dom";
import useSWR from "swr";
import {GET} from "../../../utils/requestActionConstants";
import {API_ROUTE} from "../../../utils/commonConstants";
import {fetcher} from "../../../helpers/processRequest";
import {certificateStatus} from "../../../utils/configConstants";
import LottieViewer from "../../../components/LottieViewer";
import {Alert} from "antd";
import CertificateCanvas from "./CertificateCanvas";
import placeholder from "./placeholder/certificate.jpg";
import PageNotFound from "../../../components/PageNotFound";

const PublicCertificateView = () => {
    const [searchParams] = useSearchParams()
    const enrollmentResponse = useSWR({method: GET, url: API_ROUTE + '/enrollment/dist/' + searchParams.get("dist")} , fetcher);
    const enrollment = enrollmentResponse.isLoading ? {} : enrollmentResponse.data;

    if (!enrollment?.id) {
        return <PageNotFound isLoading={enrollmentResponse.isLoading} />
    } else {
        return (
            <div>
                <div className="sticky top-0 z-50 font-semibold text-sm text-slate-900 ring-1 ring-slate-900/10"><div className="flex bg-gradient-to-r bg-slate-50/90 backdrop-blur-sm  shadow-md shadow-slate-200 p-3"><div className="from-inherit align-middle"><a className="flex" href="/"><img src="/static/media/logo.55134aa1b1092bea5cdd.png" width="100" alt="CLC" /> <span className="my-auto pl-2 text-2xl md:text-3xl font-clc ">Ceylon Ladies' Campus</span></a></div><div className="grow"></div><div className="hidden md:flex"><nav className="text-sm leading-6 font-semibold text-slate-700 dark:text-slate-200"><ul className="flex space-x-8 px-8 h-full"><li className="my-auto primary-fore-color"></li></ul></nav><div className="my-auto"><a className="py-2 px-3 bg-blue-500 text-white text-sm font-semibold rounded-md shadow-lg shadow-blue-500/50 focus:outline-none" href="https://clcsl.edu.lk">Switch to Web Site</a></div></div><div className="flex md:hidden animate-fadeIn pr-4"><div className="icon-bars my-auto"></div></div></div><div className="animate-slideOut hidden absolute top-20 z-50 right-0 bg-slate-50 w-full"><nav className="px-3 py-3 text-sm leading-6 font-semibold text-slate-700 dark:text-slate-200"><ul className="h-full w-full px-3 py-3"><li className="my-auto"><a href="/">Home</a></li></ul><div className="w-full text-center"><a className="py-2 px-3 w-full bg-blue-500 text-white text-sm font-semibold rounded-md shadow-lg shadow-blue-500/50 focus:outline-none" href="/online-application">JOIN NOW!</a></div></nav></div></div>

                <div className={'container m-auto'}>
                    <div className={'text-3xl text-center pt-10 pb-5'}>Ceylon Ladies' Campus - Certificate Verification</div>

                    {enrollment && enrollment.certificateStatus === certificateStatus.requested && (
                        <div className={'pt-4 text-center relative'}>
                            <div className={'flex justify-center'}><LottieViewer lottieName={'successCheck'} loop={false} /></div>
                            <Alert type={'warning'} className={'text-center'} message={'We received your request. You will be notified as soon as it is under review!'} />
                        </div>
                    )}

                    {enrollment && enrollment.certificateStatus === certificateStatus.underReview && (
                        <div className={'pt-4'}>
                            <Alert type={'warning'} className={'text-center'} message={'Your certificate request is currently under review. You will be notified as soon as it is approved and available!'} />
                        </div>
                    )}

                    {enrollment && (enrollment.certificateStatus === certificateStatus.approved || enrollment.certificateStatus === certificateStatus.issued) && (
                        <div>
                            <div className={'overflow-auto py-5'}>
                                <CertificateCanvas enrollment={enrollment} placeholder={placeholder}/>
                            </div>
                        </div>
                    )}

                    {enrollment && (enrollment.certificateStatus === certificateStatus.rejected) && (
                        <Alert type={'error'} className={'text-center mt-5'} message={'Your certificate request is rejected. Contact school for more details!'} />
                    )}

                    <div className={'border rounded-md p-6 mt-5 mb-10 md:w-2/3 m-auto bg-slate-800 text-white'}>
                        <div className={'grid grid-cols-2 gap-3 pb-2'}>
                            <div className={'font-bold'}>Index/Admission Number</div>
                            <div className={'break-all'}>{enrollment.legacyAdmNo}</div>
                        </div>
                        <div className={'grid grid-cols-2 gap-3 pb-2'}>
                            <div className={'font-bold'}>Student Name</div>
                            <div className={'break-all'}>: {enrollment.student?.fullName}</div>
                        </div>
                        <div className={'grid grid-cols-2 gap-3 pb-2'}>
                            <div className={'font-bold'}>Course Name</div>
                            <div className={'break-all'}>: {enrollment.course?.courseName}</div>
                        </div>
                        <div className={'grid grid-cols-2 gap-3 pb-2'}>
                            <div className={'font-bold'}>Address</div>
                            <div className={'break-all'}>: {enrollment.student?.permAddress}</div>
                        </div>
                        <div className={'grid grid-cols-2 gap-3 pb-2'}>
                            <div className={'font-bold'}>NIC</div>
                            <div className={'break-all'}>: {enrollment.student?.nic}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default PublicCertificateView;
