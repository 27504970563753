import React from "react";

const PageNotFound = ({headerHidden, isLoading}) => {
    return (
        <div className={`h-screen container`}>
            {!headerHidden && <div className="sticky top-0 z-50 font-semibold text-sm text-slate-900 ring-1 ring-slate-900/10"><div className="flex bg-gradient-to-r bg-slate-50/90 backdrop-blur-sm  shadow-md shadow-slate-200 p-3"><div className="from-inherit align-middle"><a className="flex" href="/"><img src="/static/media/logo.55134aa1b1092bea5cdd.png" width="100" alt="CLC" /> <span className="my-auto pl-2 text-2xl md:text-3xl font-clc ">Ceylon Ladies' Campus</span></a></div><div className="grow"></div><div className="hidden md:flex"><nav className="text-sm leading-6 font-semibold text-slate-700 dark:text-slate-200"><ul className="flex space-x-8 px-8 h-full"><li className="my-auto primary-fore-color"></li></ul></nav><div className="my-auto"><a className="py-2 px-3 bg-blue-500 text-white text-sm font-semibold rounded-md shadow-lg shadow-blue-500/50 focus:outline-none" href="https://clcsl.edu.lk">Switch to Web Site</a></div></div><div className="flex md:hidden animate-fadeIn pr-4"><div className="icon-bars my-auto"></div></div></div><div className="animate-slideOut hidden absolute top-20 z-50 right-0 bg-slate-50 w-full"><nav className="px-3 py-3 text-sm leading-6 font-semibold text-slate-700 dark:text-slate-200"><ul className="h-full w-full px-3 py-3"><li className="my-auto"><a href="/">Home</a></li></ul><div className="w-full text-center"><a className="py-2 px-3 w-full bg-blue-500 text-white text-sm font-semibold rounded-md shadow-lg shadow-blue-500/50 focus:outline-none" href="/online-application">JOIN NOW!</a></div></nav></div></div>}
            <div className={'mt-20 pt-20 justify-center items-center'}>
                <div className={'text-4xl text-center'}>{isLoading ? "Loading...": "404! Page Not Found"}</div>
            </div>
        </div>
    )
}

export default PageNotFound;
