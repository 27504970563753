import useSWR from "swr";
import {GET, PUT} from "../../../utils/requestActionConstants";
import {API_ROUTE} from "../../../utils/commonConstants";
import {fetcher, processReq} from "../../../helpers/processRequest";
import {useSearchParams} from "react-router-dom";
import {certificateStatus} from "../../../utils/configConstants";
import {Alert, Button, Spin} from "antd";
import React, {useState} from "react";
import placeholder from './placeholder/certificate.jpg'
import CertificateCanvas from "./CertificateCanvas";
import LottieViewer from "../../../components/LottieViewer";
import PageNotFound from "../../../components/PageNotFound";

const Certificate = () => {
    const [searchParams] = useSearchParams()
    const enrollmentResponse = useSWR({method: GET, url: API_ROUTE + '/auth/enrollment/dist/' + searchParams.get("dist")} , fetcher);
    const enrollment = enrollmentResponse.isLoading ? {} : enrollmentResponse.data;
    const [isLoading, setIsLoading] = useState(false);

    const updateCertificateStatus = async (status) => {
        let reqObject = {
            id: enrollment.id,
            certificateStatus: status
        };

        setIsLoading(true)
        await processReq(PUT, API_ROUTE + '/auth/enrollment', reqObject);
        await enrollmentResponse.mutate();
        setIsLoading(false);
    }

    const CertificateRequestForm = () => {
        return <div>
            <Alert className={'text-center mt-2'} message={'You don\'t have any certificate requests!'} />

            <div className={'border rounded-md p-4 mt-2'}>
                <div className={'text-center mt-5'}>
                    <button disabled={isLoading} className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded" onClick={() => updateCertificateStatus(certificateStatus.requested)}>
                        Request Certificate {isLoading && <Spin />}
                    </button>
                </div>
            </div>
        </div>
    }

    // const CertificateView = () => {
    //     return <div className={'overflow-scroll'}>
    //         <div className={'m-auto flex justify-center items-center'} style={{backgroundImage: `url(${placeholder})`, width: 581, height: 826}}>
    //             <div>{enrollment.student.fullName}</div>
    //         </div>
    //         <PDFViewer width="100%" height="826px">
    //             <Document ref={targetRef}>
    //                 <Page size={"A4"}>
    //                     <View>
    //                         <Image src={placeholder} style={{width: 581, height: 826, position: 'absolute', top: 0}} />
    //                         <Text style={{position: "absolute", top: 0, left: 0, right: 0, bottom: 0}}>{enrollment.student.fullName}</Text>
    //                     </View>
    //                 </Page>
    //             </Document>
    //         </PDFViewer>
    //     </div>
    // }

    if (!enrollment?.id) {
        return <PageNotFound headerHidden={true} isLoading={enrollmentResponse.isLoading}/>
    } else {
        return (
            <div>
                <div className={'border rounded-md p-4 mt-2 md:w-2/3 m-auto bg-blue-100'}>
                    <div className={'grid grid-cols-2 gap-3 pb-2'}>
                        <div className={'font-bold'}>Index/Admission Number</div>
                        <div className={'break-all'}>{enrollment.legacyAdmNo}</div>
                    </div>
                    <div className={'grid grid-cols-2 gap-3 pb-2'}>
                        <div className={'font-bold'}>Student Name</div>
                        <div className={'break-all'}>{enrollment.student?.fullName}</div>
                    </div>
                    <div className={'grid grid-cols-2 gap-3 pb-2'}>
                        <div className={'font-bold'}>Course Name</div>
                        <div className={'break-all'}>{enrollment.course?.courseName}</div>
                    </div>
                    <div className={'grid grid-cols-2 gap-3 pb-2'}>
                        <div className={'font-bold'}>Address</div>
                        <div className={'break-all'}>{enrollment.student?.permAddress}</div>
                    </div>
                    <div className={'grid grid-cols-2 gap-3 pb-2'}>
                        <div className={'font-bold'}>NIC</div>
                        <div className={'break-all'}>{enrollment.student?.nic}</div>
                    </div>
                </div>
                {((enrollment && !enrollment.certificateStatus) || enrollment.certificateStatus === certificateStatus.inactive) &&
                    <CertificateRequestForm/>}

                {enrollment && enrollment.certificateStatus === certificateStatus.requested && (
                    <div className={'pt-4 text-center relative'}>
                        <div className={'flex justify-center'}><LottieViewer lottieName={'successCheck'} loop={false}/>
                        </div>
                        <Alert type={'warning'} className={'text-center'}
                               message={'We received your request. You will be notified as soon as it is under review!'}/>
                    </div>
                )}

                {enrollment && enrollment.certificateStatus === certificateStatus.underReview && (
                    <div className={'pt-4'}>
                        <Alert type={'warning'} className={'text-center'}
                               message={'Your certificate request is currently under review. You will be notified as soon as it is approved and available!'}/>
                    </div>
                )}

                {enrollment && (enrollment.certificateStatus === certificateStatus.approved || enrollment.certificateStatus === certificateStatus.issued) && (
                    <div>
                        <Alert type={'success'} className={'text-center my-10'} message={'Your certificate is ready!'}/>

                        <div className={'overflow-auto'}>
                            <CertificateCanvas enrollment={enrollment} placeholder={placeholder}/>
                        </div>
                    </div>
                )}

                {enrollment && (enrollment.certificateStatus === certificateStatus.rejected) && (
                    <Alert type={'error'} className={'text-center mt-5'}
                           message={'Your certificate request is rejected. Contact school for more details!'}/>
                )}
            </div>
        )
    }
}

export default Certificate;
